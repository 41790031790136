import React, { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import logo from "./assets/images/logo.png"; // Assuming your logo is in the src folder
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  animate,
} from "framer-motion";
import Ticker from "./components/Ticker/Ticker";
import { FiArrowRight } from "react-icons/fi";
import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

const App = () => {
  return (
    <div className="flex flex-col justify-center h-screen w-screen text-center">
      <div
        className="absolute top-4 right-4 text-black text-sm px-2 py-1 rounded-full flex items-center gap-2 font-poppins font-light"
        style={{ backgroundColor: "rgba(200, 200, 200, 0.2)" }}
      >
        {/* Green Dot Icon */}
        <span
          className="w-2.5 h-2.5 rounded-full"
          style={{
            backgroundColor: "#13FFAA",
          }}
        ></span>
        <span>Beta</span>
      </div>

      <div className="relative z-10 flex flex-1 flex-col items-center justify-center">
        {/* <img src={logo} className="w-36 h-auto" alt="Logo" /> */}
        <img src={logo} className="w-40 h-auto sm:w-28 md:w-52" alt="Logo" />
      </div>
      <div className="relative z-10 flex flex-1 flex-col items-center justify-center px-4 py-2 sm:px-6 sm:py-3">
        <Header />
        <div className="flex gap-4 mb-8 md:mb-16">
          <a
            href="http://calendly.com/jumpshotlabs/intro-call" // Replace with your link
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              whileHover={{ scale: 1.015, y: -5 }} // Move up on hover
              whileTap={{ scale: 0.985 }}
              className="group relative font-poppins text-sm sm:text-base px-4 sm:px-6 py-2 sm:py-3 cursor-pointer flex items-center gap-1.5 rounded-full bg-black text-white shadow-md transition-all duration-150 ease-in-out" // Default shadow
            >
              Schedule a call
              <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
              {/* Shadow effect on hover */}
              <style jsx>{`
                button:hover {
                  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
                }
              `}</style>
            </button>
          </a>
        </div>
      </div>
      {/* <Ticker /> */}
      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={50} count={1000} factor={4} fade speed={1} />
        </Canvas>
      </div>
    </div>
  );
};

export default App;
